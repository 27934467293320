import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { HomePageTemplate } from '@components';

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <HomePageTemplate
      description={frontmatter.description}
      heading={frontmatter.heading}
      seo={frontmatter.seo}
      hero={frontmatter.hero}
      whyUs={frontmatter.whyUs}
      offer={frontmatter.offer}
      art={frontmatter.art}
      newsletter={frontmatter.newsletter}
    />
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
};

export default HomePage;

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        hero {
          title
          markdown
          buttons {
            text
            path
            visible
            color
          }
          image {
            alt
            src {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        whyUs {
          title
          attributes {
            title
            text
          }
          button {
            text
            path
            visible
            color
          }
          movie
          image {
            alt
            src {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          imageLarge {
            alt
            src {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        offer {
          visible
          title
          markdown
          button {
            text
            path
            visible
            color
          }
          items {
            title
            text
            path
            image {
              alt
              src {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
        art {
            visible
            title
            markdown
            button {
              text
              path
              visible
              color
            }
            image {
              alt
              src {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            imageLarge {
              alt
              src {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        newsletter {
          visible
          title
          markdown
          button {
            text
            color
          }
        }
        seo {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
